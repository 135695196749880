@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";
@import "../client-variables/overrides.scss";

.view-more-btn-wrapper{
  padding: 10px;
  display: flex;
  justify-content: center;
}
.pin-input{
  min-width: 50px !important;
}
.cursor-pointer:hover{
  cursor: pointer;
}
.margin-bottom{
  margin-bottom: 20px;
}

.editor-class {
  background-color: white;
  height: 55vh !important;
  overflow-y: scroll !important;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: 0 10px 0 10px;
}